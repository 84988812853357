<template>
  <div  class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Discounts</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>

        <table ref="serviceTable" class="table mt-3">
          <thead>
          <tr>
            <th>ID</th>
            <th>Code</th>
            <th>Value</th>
            <th>Min Bill Amount</th>
            <th>Max Usages Per Customer</th>
            <th>Is Special Day Discount</th>
            <th>Valid From</th>
            <th>Valid Till</th>
            <th>Description</th>
            <th>Active</th>
            <th>Applied Orders</th>
            <th>Visible to Public</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="discount in discounts" :key="discount.discountId">
            <td>{{ discount.discountId }}</td>
            <td>{{ discount.code }}</td>
            <td v-if="discount.type === 'percentage'">{{ discount.value.toFixed(2)+'%' }}</td>
            <td v-else>{{ '₹'+discount.value.toFixed(2) }}</td>
            <td>{{ discount.minBillingValue }}</td>
            <td>{{ discount.maxUsagePerCustomer }}</td>
            <td>{{ discount.isSpecialDayDiscount }}</td>
            <td>{{ discount.validFrom }}</td>
            <td>{{ discount.validTill }}</td>
            <td>{{ discount.description }}</td>
            <td>
              <p v-if="discount.isActive" class="ongoing" style="text-align:center">{{ discount.isActive }}</p>
              <p v-else class="progress1" style="text-align:center">{{ discount.isActive }}</p>
            </td>
            <td>{{ discount.appliedOrders }}</td>
            <td>{{ discount.isPublic }}</td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(discount,true,'edit')">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
                <a href="#" @click="openEditModal(discount,true,'block')" v-if="discount.isActive">
                    <img :src="require('@/assets/images/block.png')" width="25" height="25" class="btn-tbl-img1">
                  </a>
                  <a href="#" @click="openEditModal(discount,true,'unblock')" v-else>
                    <img :src="require('@/assets/images/unlocked.png')" width="25" height="25" class="btn-tbl-img2">
                  </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div id="dataModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title"> <span v-if="action==='edit'">
                    Edit Discount
                  </span>
                  <span v-if="action==='add'">
                    Add Discount
                  </span>
                  <span v-if="action==='block'">
                    Disabled Discount
                  </span>
                  <span v-if="action==='unblock'">
                    Enable Discount
                  </span></h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <div v-if="action==='edit' || action==='add'">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Code </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedData.code" class="form-control" placeholder="Code">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Type</label>
                  <div class="col-sm-8">
                    <select  v-model="updatedData.type" class="form-select">
                      <option value="fixed-amount">Fixed Amount</option>
                      <option value="percentage">Percentage</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Value</label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedData.value" @input="allowNumbersValue" class="form-control" placeholder="Value">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Min Billing Amount</label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedData.minBillingValue" @input="allowNumbersBill" class="form-control" placeholder="Min Billing Amount">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Max  Usages Per Customer</label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedData.maxUsagePerCustomer" @input="allowNumbersMaxUsage" class="form-control" placeholder="Max Usages Per Customer ">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Is Special Day Discount</label>
                  <div class="col-sm-8">
                    <select v-model="updatedData.isSpecialDayDiscount" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Valid From</label>
                  <div class="col-sm-8">
                    <input type="date" v-model="updatedData.validFrom" class="form-control" placeholder="valid From">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Valid Till</label>
                  <div class="col-sm-8">
                    <input type="date" v-model="updatedData.validTill" class="form-control" placeholder="Valid Till">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Description</label>
                  <div class="col-sm-8">
                    <input type="textArea" v-model="updatedData.description" class="form-control" placeholder="Description">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Is Visible to Public</label>
                  <div class="col-sm-8">
                    <select v-model="updatedData.isPublic" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
            </div>
                <div v-if="action==='block' || action==='unblock'">
                  <span>
                    Are you sure you want to {{ action==='block'?'disable':'enable' }} ?
                  </span>
                </div>
                
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="update">
                  <span v-if="action==='edit'">
                    Update
                  </span>
                  <span v-if="action==='add'">
                    Add
                  </span>
                  <span v-if="action==='block'">
                    Disable
                  </span>
                  <span v-if="action==='unblock'">
                    Enable
                  </span></button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import discountCodeAPI from '@/api/OrdersPayment/DiscountCode';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
export default {
  data() {
    return {
      editingService: null,
      discounts: [],
      editFlag: false,
      updatedData: {},
      action:'',
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    openEditModal(discount, flag,action) {
      this.editingService = discount;
      this.editFlag = flag;
      this.action=action;
      const modal = document.getElementById('dataModal');
      if (this.editFlag) {
        this.updatedData= JSON.parse(JSON.stringify(discount));
      } else {
        this.action='add';
        this.updatedData ={
          discountId : '',
          description: '',
          type :'',
          value : '',
          minBillingValue : '',
          validFrom : '',
          validTill : '',
          maxUsagePerCustomer : '',
          isSpecialDayDiscount : true,
          isActive : true,
          isPublic: true,
        };
       
      }

      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.updatedData ={
          discountId : '',
          description: '',
          type :'',
          value : '',
          minBillingValue : '',
          validFrom : '',
          validTill : '',
          maxUsagePerCustomer : '',
          isSpecialDayDiscount : true,
          isActive : true,
          isPublic: true,
        };
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }

    },
    async fetchDiscountCodes() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await discountCodeAPI.getDiscountCodes();
        this.discounts= [];
        if(response && response.data){
          response.data.forEach(element => {
          if(element.validFrom){
            element.validFrom=element.validFrom.split('T')[0]
          }
          if(element.validTill){
            element.validTill=element.validTill.split('T')[0]
          }
        });
        }
        
        this.discounts = response.data;
        
        // this.loading=false
        this.initializeDataTable();
      } catch (error) {
        console.error(error,'Error fetching discounts:');
        alert(error.response.data.message);
        // store.commit('home/SET_LOADING_PAGE_STATUS',false)
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async update() {
      try {
        this.$store.commit('home/START_LOADING')
        let response = null;
        console.log(this.updatedData)
        if(this.action==='edit' || this.action==='add'){
        if (this.editFlag) {
          if(this.updatedData.validFrom){
            this.updatedData.validFrom = this.updatedData.validFrom.split('T')[0];

          }
          if(this.updatedData.validTill){
            this.updatedData.validTill = this.updatedData.validTill.split('T')[0];
          }
        const updateDiscount = {
            code: this.updatedData.code,
            discountId: this.updatedData.discountId,
            value: this.updatedData.value,
            minBillingValue: this.updatedData.minBillingValue,
            maxUsagePerCustomer: this.updatedData.maxUsagePerCustomer,
            isSpecialDayDiscount: this.updatedData.isSpecialDayDiscount,
            validFrom: this.updatedData.validFrom,
            validTill: this.updatedData.validTill,
            description: this.updatedData.description,
            isActive: this.updatedData.isActive,
            type: this.updatedData.type,
            isPublic: this.updatedData.isPublic,
          };
          response = await discountCodeAPI.updateDiscountCode(updateDiscount);
        } else {
          const updateDiscount = {
            code: this.updatedData.code,
            value: this.updatedData.value,
            minBillingValue: this.updatedData.minBillingValue,
            maxUsagePerCustomer: this.updatedData.maxUsagePerCustomer,
            isSpecialDayDiscount: this.updatedData.isSpecialDayDiscount,
            validFrom: this.updatedData.validFrom,
            validTill: this.updatedData.validTill,
            description: this.updatedData.description,
            isActive: this.updatedData.isActive,
            type: this.updatedData.type,
            isPublic: this.updatedData.isPublic,
          };
          response = await discountCodeAPI.addDiscountCode(updateDiscount);
        }
      } else {
        response = await discountCodeAPI.updateDiscountStatus(this.updatedData.discountId);
      }
      console.log(response)

        if (response.data.success) {
          alert(response.data.message);
          this.closeEditModal();
          await this.fetchDiscountCodes();
        }

      } catch (error) {
        console.error(error, 'Error while updating/adding services:');
        alert(error.response.data.message?error.response.data.message:error.response.data.detail);
        await   this.fetchDiscountCodes();
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    getExportTitle() {
      return 'Services';
    },
    allowNumbersMaxUsage() {
      this.updatedData.maxUsagePerCustomer = this.updatedData.maxUsagePerCustomer.replace(/[^0-9.]/g, '');
    },
    allowNumbersValue() {
      this.updatedData.value = this.updatedData.value.replace(/[^0-9.]/g, '');
    },
    allowNumbersBill() {
      this.updatedData.minBillingValue = this.updatedData.minBillingValue.replace(/[^0-9.]/g, '');
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.serviceTable)) {
        $(this.$refs.serviceTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2, 3, 4];

      this.$nextTick(() => {
        $(this.$refs.serviceTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    // this.loading=true
    this.fetchDiscountCodes();
    
  },
};
</script>
